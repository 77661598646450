
import React from 'react';
import { Form, Icon, Input, Button, Checkbox ,message,Row, Col} from 'antd';
import {Redirect,Link} from 'react-router-dom'
import { connect } from 'react-redux';
import {register,routeData,iccidData} from '../../redux/user.redux'
import scanlogo from '../../../static/images/scanlogo.png'
import request from '../../util/request'
import './Register.less'

const FormItem = Form.Item;
@Form.create()
@connect(
	state=>state.user,
	{register,routeData,iccidData}
)
class RegStep1 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loading:false,
    };
  }

  componentDidMount() {
    // this.props.initregTo()
    const { location } = this.props;
    console.log("step1---->>>"+this.props.location.pathname);
    this.props.routeData(location)
  }
  _toggleLoading = (status = false) => {
    this.setState({
      loading: status
    })
  }

  handleSubmit = e => {
    e.preventDefault()
      this.props.form.validateFields(async (err, values) => {
        if(!err){
            // this.props.register(values,this._toggleLoading)
            request(this._toggleLoading)({
        			method: 'post',
        			url: '/admin/register',
        			data: {
        				...values
        			}
        		})
        		.then(res => {
        			console.log("res---->>>>>",res)
              this.props.iccidData(res)
        					// dispatch(iccidSuccess(res))
              this.props.history.push('/register/step2')
        		})
          }
      })
  };

  render() {
    let {getFieldDecorator} = this.props.form;
    console.log("propslocation->>>"+JSON.stringify(this.props) );
    return (
      <div className="register-stp1">
        <div className="reg-form" style={{height: '440px'}}>
        <Form onSubmit={this.handleSubmit} >
          <FormItem>
            {getFieldDecorator('iccidNum', {
              rules: [
                {
                  required: true,
                  message: '请输入ICCID',
                },
                {
                  len:19,
                  message:'ICCID错误',
                },
              ],
            })(<Input size="large" placeholder="请输入ICCID" />)}
          </FormItem>
          <FormItem>
            <Button
              size="large"
              loading={this.state.loading}
              className="submit"
              type="primary"
              htmlType="submit"
            >
              绑定手机
            </Button>
            <Link className="loginLink" to="/login">
              已有账户登录
            </Link>
          </FormItem>
					<div className="scanimg" >
						<img
							src={scanlogo}
							alt="scan"
							style={{width: '300px'}}>
						</img>
					</div>
        </Form>

      </div>
    </div>
    );
  }
}

export default RegStep1;
