
import axios from 'axios'
import request from '../util/request'
import {getRedirectPath} from '../util'

const AUTH_SUCCESS = 'AUTH_SUCCESS'
const ICCID_SUCCESS = 'ICCID_SUCCESS'
const INITREGTO = 'INITREGTO'
const LOGOUT = 'LOGOUT'
const ERROR_MSG = 'ERROR_MSG'
const LOAD_DATA = 'LOAD_DATA'
const REDIRECTTO = 'REDIRECTTO'
const ICCIDDATA = 'ICCIDDATA'
const initState={
	redirectTo:'',
	msg:'',
	username:'',
	type:'',
	phonenumber:'',
	role:'',
	iccidNum:'',
}

const initReg={
	iccidNum:'',
}
// reducer
export function user(state=initState, action){
	switch(action.type){
		case AUTH_SUCCESS:
			return {...state,redirectTo:'/admin/home',...action.payload}
		case ICCID_SUCCESS:
			return {...state,regTo:'/regphone',...action.payload}
		case LOAD_DATA:
			return {...state,...action.payload}
		case ERROR_MSG:
			return {...state, isAuth:false, msg:action.msg}
		case LOGOUT:
			return {...initState,redirectTo:'/login'}
		case REDIRECTTO:
			return{...state,...action.payload}
		case ICCIDDATA:
			return {...state,...action.payload}
		default:
			return state
	}
}

function authSuccess(obj){
	const {...data} = obj
	console.log("data---->>>>>",data)
	return {type: AUTH_SUCCESS, payload:data}
}

function loadSuccess(obj){
	const {...data} = obj
	return {type: LOAD_DATA, payload:data}
}

function iccidSuccess(obj){
	const {...data} = obj
	console.log("iccid---->>>>>",data)
	return {type: ICCID_SUCCESS, payload:data}
}

function errorMsg(msg){
	return { msg, type:ERROR_MSG }
}

export function loadData(userinfo){
	return { type:LOAD_DATA, payload:userinfo}
}

// export function loadData(){
// 	return dispatch=>{
// 		request(()=>{})({
// 			method: 'get',
// 			url: '/admin/info'
// 		})
// 		.then(res => {
// 			console.log("loadRes---->>>>>",res)
// 					dispatch(loadSuccess(res))
// 		})
// 	}
// }
function logout(){
	return { type:LOGOUT }
}
export function logoutSubmit(){
	return dispatch=>{
		axios.get('/admin/logout')
		.then(res => {
			console.log("logoutres---->>>>>",res)
					dispatch(logout())
		})
	}

}

export function login(values,fn){
	return dispatch=>{
		request(fn)({
			method: 'post',
			url: '/admin/login',
			data: {
				...values
			}
		})
		.then(res => {
			console.log("res---->>>>>",res)
					dispatch(authSuccess(res))
		})
	}
}

export function register(values,fn){
	return dispatch=>{
		request(fn)({
			method: 'post',
			url: '/admin/register',
			data: {
				...values
			}
		})
		.then(res => {
			console.log("res---->>>>>",res)
					dispatch(iccidSuccess(res))
		})
	}
}

export function routeData(routeDir){
	const {...data} = routeDir
	console.log("data---->>>>>",data)
	return { type:REDIRECTTO ,payload:routeDir}
}

export function iccidData(iccid){
	const {...data} = iccid
	console.log("data---->>>>>",data)
	return { type:ICCIDDATA ,payload:data}
}
