/**
 * Created by YeMeng
 */
import React from 'react';
import { Form, Icon, Input, Button, Checkbox ,message} from 'antd';
import {Redirect,Link} from 'react-router-dom'
import { connect } from 'react-redux';
import {login} from '../../redux/user.redux'
import request from '../../util/request'
import "../../style/login.less"

const FormItem = Form.Item;

@connect(
	state=>state.user,
	{login}
)

@Form.create()
export default class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      verifyCode:null
    }
  }
  _toggleLoading = (status = false) => {
    this.setState({
      loading: status
    })
  }
  _toggleinfo = () => {
    message.info('请进行人机校验')
  }

  handleReset = () => {
    this.setState({verifyCode:null})
    LUOCAPTCHA.reset()
  }

  handleSubmit = (e) => {
    e.preventDefault()
      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          // values.push({verifyCode:this.state.verifyCode})
          if(this.state.verifyCode){
            values.verifyCode = this.state.verifyCode
            this.handleReset()
          }
          else{
            // this._toggleinfo()
            // return
          }
          this.props.login(values,this._toggleLoading)
          }
        })
  }

  componentWillMount () {
    const script = document.createElement("script")
    script.src = "//captcha.luosimao.com/static/dist/api.js"
    script.async = true
    document.body.appendChild(script)
    window.handleLuotest=(resp) => {
      this.setState({verifyCode:resp})
    }
  }

    render() {
      let {getFieldDecorator} = this.props.form;
        return (
            <div className="login">
              {(this.props.redirectTo&&this.props.redirectTo!='/login')? <Redirect to={this.props.redirectTo} />:null}
                <div className="login-form" style={{height: '460px'}}>
                    <div className="login-logo">
                      <div className="iot">
                        <span>MNO物联网平台</span>
                      </div>
                    </div>
                    <Form onSubmit={this.handleSubmit} style={{maxWidth: '300px'}}>
                        <FormItem>
                            {getFieldDecorator('phonenumber', {
                                rules: [{ required: true, message: '请输入用户名!' },{len:11,message:'手机号错误'}],
                            })(
                                <Input prefix={<Icon type="user" style={{ fontSize: 13 }} />} placeholder="电话号码" />
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '请输入密码!' }],
                            })(
                                <Input prefix={<Icon type="lock" style={{ fontSize: 13 }} />} type="password" placeholder="密码" />
                            )}
                        </FormItem>
                        <FormItem>
                        {
                        //getFieldDecorator('lc-captcha-response')(
                        //<div className="l-captcha" data-site-key="cef0fa058806a13f20619804fce16905" data-callback="handleLuotest" ></div>
                        //)
                      }

                        </FormItem>
                        <FormItem>
                            <Button type="primary" htmlType="submit" className="login-form-button" style={{width: '100%'}} loading={this.state.loading}>
                                登录
                            </Button>

                            <p style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Link to="/register">或 现在注册!</Link>

																{
																	//<Link className="login-form-forgot" to="" style={{float: 'right'}}>忘记密码</Link>
																}


                            </p>
                        </FormItem>
                    </Form>
                    <div className="beian">
                      <div className="beianNB">
                        <a href="https://beian.miit.gov.cn">苏ICP备2021018988号-1	</a>
                      </div>
                    </div>
                    <div className="banquan">
                      <div className="banquanNB">版权所有:南京科青信息科技有限公司</div>
                    </div>
                </div>
            </div>

        )
    }
}

// export default Login = Form.create({})(Login)
