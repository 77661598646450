import React, { PureComponent, Fragment } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Card, Steps } from 'antd';
import NotFound from '../404';
import { connect } from 'react-redux';
import { getRoutes } from '../../util';
import styles from './style.less';
import RegStep1 from './Step1'
import RegStep2 from './Step2'
import RegStep3 from './Step3'

const { Step } = Steps;
@connect(
	state=>state.user
)
export default class StepForm extends PureComponent {
  constructor(props) {
    super(props)
  }
  getCurrentStep=() =>{
    if(this.props.pathname){
      console.log('stepReg--->'+this.props.pathname);
      const pathList = this.props.pathname.split('/');
      switch (pathList[pathList.length - 1]) {
        case 'step1':
          return 0;
        case 'step2':
          return 1;
        case 'step3':
          return 2;
        default:
          return 0;
      }
    }
    return 0;
  }

  render() {
    return (
        <Card bordered={false}>
          <Fragment>
            <Steps current={this.getCurrentStep()} className={styles.steps}>
              <Step title="填写ICCID" />
              <Step title="绑定手机" />
              <Step title="实名认证" />
            </Steps>
            {this.props.children}
          </Fragment>
        </Card>
    );
  }
}
