import React from 'react'
import ReactDom from 'react-dom'
import './style/lib/animate.css';
import './style/global.css';
import './index.scss'
import PropTypes from 'prop-types';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import RouteMap from './routes'
import reducers from './reducers'
import thunk from 'redux-thunk'
// import './axiosToast'
import { BrowserRouter, Route, Redirect,Switch } from 'react-router-dom'

// import configureStore from './store/configureStore'

// 创建 Redux 的 store 对象
const store = createStore(reducers, compose(
	applyMiddleware(thunk),
	window.devToolsExtension?window.devToolsExtension():f=>f
))

ReactDom.render(
  (<Provider store={store}>
     <RouteMap/>
   </Provider>
   ),document.getElementById('app')
)
