const menuList = [
    {
        title: '首页',
        key: '/admin/home',
        icon: 'dashboard',
    },
    {
        title: '设备',
        key: '/admin/device',
        icon: 'code',
        sub: [
            {
                title: '设备列表',
                key: '/admin/device/sims',
                icon: '',
            },
            {
                title: '资源配置',
                key: '/admin/device/sources',
                icon: '',
            },
            {
                title: '出入库',
                key: '/admin/device/inorout',
                icon: '',
            },
            {
                title: '批量更新',
                key: '/admin/device/uploads',
                icon: '',
            }
        ]
    },
    {
        title: '套餐管理',
        key: '/admin/package',
        icon: 'gift',
        sub: [
          {
              title: '套餐添加',
              key: '/admin/package/addpackage',
              icon: '',
          },
            {
                title: '套餐列表',
                key: '/admin/package/packageList',
                icon: '',
            }
        ]
    },
    {
        title: '计费',
        key: '/admin/billing',
        icon: 'pay-circle',
        sub: [
            {
                title: '账单',
                key: '/admin/billing/invoices',
                icon: '',
            },
            {
                title: '用量',
                key: '/admin/billing/usages',
                icon: '',
            },
            {
                title: '总览',
                key: '/admin/billing/mybill',
                icon: '',
            },
        ]
    },
    {
        title: '客户管理',
        key: '/admin/user',
        icon: 'team',
        sub: [
            {
                title: '客户列表',
                key: '/admin/user/userList',
                icon: '',
            },
            {
              title: '增加客户',
              key: '/admin/user/addUser',
              icon: '',
            }
        ]
    },
    {
        title: '订单管理',
        key: '/admin/orders',
        icon: 'shop',
        sub: [
            {
                title: 'sim订单',
                key: '/admin/orders/simOrders',
                icon: '',
            },

        ]
    },
    {
        title: '流量池',
        key: '/admin/pools',
        icon: 'loading',
    },
    {
        title: '实名管理',
        key: '/admin/cert',
        icon: 'idcard',
        sub: [
            {
                title: '提交实名',
                key: '/admin/cert/aplly',
                icon: '',
            },
            {
                title: '实名列表',
                key: '/admin/cert/certList',
                icon: '',
            }
        ]
    },

    {
        title: '设备地图',
        key: '/admin/map',
        icon: 'google',
    },
    {
        title: '统计分析',
        key: '/admin/realytics',
        icon: 'bar-chart',
    },

    {
        title: '业务办理',
        key: '/admin/business',
        icon: 'tool',
        sub: [
            {
                title: '实名制查询',
                key: '/admin/business/cert',
                icon: '',
            },
            {
                title: '区域查询',
                key: '/admin/business/area',
                icon: '',
            },
            {
                title: '限速',
                key: '/admin/business/rate',
                icon: '',
            },
            {
                title: '自动化规则',
                key: '/admin/business/automationRule',
                icon: '',
            },
            {
                title: '套餐充值',
                key: '/admin/business/package',
                icon: '',
            },
        ]
    },

    {
        title: '系统设置',
        key: '/admin/setting',
        icon: 'setting',
        sub: [
            {
                title: '角色',
                key: '/admin/setting/permission',
                icon: '',
            },
            {
                title: '账号',
                key: '/admin/setting/account',
                icon: '',
            },
            {
                title: '平台参数',
                key: '/admin/setting/logo',
                icon: '',
            },
            {
                title: '微信参数',
                key: '/admin/setting/wechat',
                icon: '',
            },
            {
                title: '下载任务',
                key: '/admin/setting/download',
                icon: '',
            },
            {
                title: '操作日志',
                key: '/admin/setting/log',
                icon: '',
            },
            {
                title: '消息管理',
                key: '/admin/setting/message',
                icon: '',
            },

        ]
    },
];
export default menuList;
