import React from 'react'
import Moment from 'moment'
import axios from 'axios'
import { Card, Form,Button,Modal,Input,Radio,DatePicker,Select,Tooltip,Icon,Menu,Dropdown,Checkbox,Row,Col,Pagination,Table} from 'antd'
// import axios from '../../axios'
// import BaseForm from '../../components/BaseForm'
const FormItem = Form.Item;
const Option = Select.Option;
const { Search } = Input;
const RadioGroup = Radio.Group;
const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      render: name => `${name.first} ${name.last}`,
      width: '20%',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
      width: '20%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

export default class Source extends React.Component{

    state = {
        bikeInfo:{}
    }


    params = {
        page:1
    }
    

    // 列表请求
    requestList = ()=>{

    }

    // 查询表单
    handleSubmit = e => {
        e.preventDefault();
        this.sourceForm.props.form.validateFields((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values);
            this.setState({
                isVisible:false
            })
          }
        });
      };

    componentDidMount(){
        this.requestList();
        this.fetch();
    }

    //处理表格变化事件
    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          pagination: pager,
        });
        this.fetch({
          results: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
        });
      };
//处理表单请求接口
    fetch = (params = {}) => {
        console.log('params:', params);
        this.setState({ loading: true });
        axios.get('https://randomuser.me/api',{
            params:{
                results: 20,
                ...params,
            }   
        },{
            type: 'json'
        })
			.then(res=>{
				
                const pagination = { ...this.state.pagination };
                // Read total count from server
                // pagination.total = data.totalCount;
                pagination.total = 200;
                this.setState({
                  loading: false,
                  data: res.data.results,
                  pagination,
                });
				
			})
      };

    handleFilterSubmit = e=>{
        e.preventDefault();
        this.filterForm.props.form.validateFields((err, values) => {
          if (!err) {
            console.log('Received filter of form: ', values);
            this.setState({
                isFilterVisible:false
            })
          }
        });
    }


    handleOperator = (type)=>{
        let item = this.state.selectedItem;
        if(type =='create'){
            this.setState({
                title:'创建资源',
                isVisible:true,
                type
            })
        }else if(type=="filter"){
                
            this.setState({
                filterTitle:'筛选条件',
                isFilterVisible:true
            })
        
    }
    else if(type=="field"){
                
        this.setState({
            isFieldVisible:true
        })
    
}
        else if(type=="edit" || type=='detail'){
            if(!item){
                Modal.info({
                    title: '信息',
                    content: '请选择一个用户'
                })
                return;
            }
            this.setState({
                title:type=='edit'?'编辑用户':'查看详情',
                isVisible:true,
                userInfo:item,
                type
            })
        }else if(type=="delete"){
            if(!item){
                Modal.info({
                    title: '信息',
                    content: '请选择一个用户'
                })
                return;
            }
            Utils.ui.confirm({
                text:'确定要删除此用户吗？',
                onOk:()=>{
                    axios.ajax({
                        url:'/user/delete',
                        data:{
                            params:{
                                id:item.id
                            }
                        }
                    }).then((res)=>{
                        if(res.code ==0){
                            this.setState({
                                isVisible:false
                            })
                            this.requestList();
                        }
                    })
                }
            })
        }
    }

    handleSearch = value=>{
        console.log('searchLog--->>>',value)
    }

    handleMenuClick = e =>{
        console.log('click', e);
    }

    handleRedo =e=>{
        console.log('redo', e);
    }
    fieldChange =checkedValues=>{
        console.log('checked = ', checkedValues);
    }

    showTotal = total=>{
        return `${total} 条`;
    }

    pageChange=page=>{
        console.log('page = ', page);

    }
    sizeChange=(current,size)=>{
        console.log('current = ', current);
        console.log('size = ', size);
    }

    render(){
        return (
            <div>
                <Card size={"small"}>
                    <Row>
                    <Col span={2}>
                        <Button type="primary" icon="plus-circle" onClick={()=>this.handleOperator('create')}
                        style={{ margin:5 }}>新增</Button>
                    </Col>
                    <Col span={4}>
                    <Search placeholder="input search text" onSearch={value => this.handleSearch(value)} 
                        style={{ width: 200,margin:5 }} enterButton/>

                    </Col>
                    <Col span={2}>
                    <Button type="link" icon="filter" onClick={()=>this.handleOperator('filter')}
                        style={{ margin:5 }}>筛选</Button>

                    </Col>
                    <Col span={4}>
                    <Dropdown 
                            overlay={(<Menu onClick={this.handleMenuClick} >
                                <Menu.Item key="1">导出</Menu.Item>
                             </Menu>)} >
                            <Button type="link" style={{ margin:5 }}>
                             操作
                            < Icon type="down" />
                            </Button>
                        </Dropdown>
                    </Col>
                    <Col span={9}>
                        <Pagination style={{ marginTop:10 ,float:'right'}} size="small" 
                        defaultCurrent={1} total={5000000} 
                        showSizeChanger showTotal={total=>this.showTotal(total)}
                        onChange={page=>this.pageChange(page)}
                        onShowSizeChange={(current,size)=>this.sizeChange(current,size)}
                        />
                    </Col>
                    <Col span={1}>
                    <Button type="link" icon="redo" shape="circle" onClick={()=>this.handleRedo()}
                         style={{ margin:5}}/>
                    </Col>

                    <Col span={2}>
                    
                    <Button  icon="table" shape="round" onClick={()=>this.handleOperator('field')}
                         style={{ margin:5}}>显示字段
                         </Button>
                    </Col>                        
                    </Row>
                    
                </Card>
                <Card style={{marginTop:10}}>
                <Table
                     columns={columns}
                     rowKey={record => record.login.uuid}
                     dataSource={this.state.data}
                     loading={this.state.loading}
                     rowSelection={rowSelection}
                     pagination={false}
                     scroll={{x:"100%",y:500}}
                     onChange={this.handleTableChange}
                  />
                </Card>
                <Modal
                    title={this.state.title}
                    visible={this.state.isVisible}
                    onOk={this.handleSubmit}
                    width={800}
                    onCancel={()=>{
                        this.sourceForm.props.form.resetFields();
                        this.setState({
                            isVisible:false
                        })
                    }}
                >
                    <SourceForm wrappedComponentRef={(inst) => this.sourceForm = inst}/>
                </Modal>

                <Modal
                    title={this.state.filterTitle}
                    visible={this.state.isFilterVisible}
                    onOk={this.handleFilterSubmit}
                    width={800}
                    onCancel={()=>{
                        this.filterForm.props.form.resetFields();
                        this.setState({
                            isFilterVisible:false,
                        })
                    }}
                >
                    <FilterForm wrappedComponentRef={(inst) => this.filterForm = inst}/>
                </Modal>

                <Modal
                    visible={this.state.isFieldVisible}
                    mask={false}
                    width={150}
                    style={{top:200,float:'right'}}
                    footer={null}
                    closable={false}
                    onCancel={()=>{
                        this.setState({
                            isFieldVisible:false,
                        })
                    }}
                >
                    <Checkbox.Group style={{ width: '100%' }} onChange = {checkedValues=>this.fieldChange(checkedValues)}>

                                <Checkbox value="A">A</Checkbox>
                                <br />
                                <Checkbox disabled value="B">
                                  B
                                </Checkbox>
                                <br />
                                <Checkbox value="C">C</Checkbox>
                                <br />
                                <Checkbox value="D">D</Checkbox>
                                <br />
                                <Checkbox value="E">E</Checkbox>
                                <br />

                    </Checkbox.Group>
                </Modal>
            </div>
        );
    }
}

class SourceForm extends React.Component{

    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {span: 5},
            wrapperCol: {span: 16}
        };
        const userInfo = this.props.userInfo || {};
        const type = this.props.type;
        return (
            <Form layout="horizontal">
                <FormItem label="资源名称" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('sourceName',{
                            rules: [
                                {
                                  required: true,
                                  message: 'Please input user password!',
                                }]
                        })(
                            <Input  placeholder="请输入资源名称"/>
                    )}
                </FormItem>
                <FormItem label="运营商类型" {...formItemLayout}>
                    {
                        getFieldDecorator('operator',{
                            rules: [
                                {
                                  required: true,
                                  message: 'Please select operator!',
                                }]
                        })(
                        <Select>
                            <Option value={1}>移动</Option>
                            <Option value={2}>联通</Option>
                            <Option value={3}>电信</Option>
                        </Select>
                        )}
                </FormItem>
                <FormItem label="平台类型" {...formItemLayout}>
                    {
                        getFieldDecorator('operatorST',{
                            rules: [
                                {
                                  required: true,
                                  message: 'Please select operatorST!',
                                }]
                        })(
                        <Select>
                            <Option value={1}>移动CTBOSS</Option>
                            <Option value={2}>联通JASPER</Option>
                            <Option value={3}>联通CMP</Option>
                            <Option value={4}>电信DCP</Option>
                            <Option value={5}>电信CMP</Option>
                        </Select>
                    )}
                    
                </FormItem>
                <FormItem  label={
                    <span>门户账号&nbsp;
                        <Tooltip title="联通jasper账户请使用单API账号，UI账号会要求定期修改密码，造成轮询失效">
                            <Icon type="question-circle-o" />
                        </Tooltip>
                     </span>
                     } {...formItemLayout}>
                    {
                        
                        getFieldDecorator('userName',{
                            rules: [
                                {
                                  required: true,
                                  message: 'Please input username!',
                                }]
                        })(
                            <Input  placeholder="请输入门户登录账号"/>
                    )}
                </FormItem> 
                <FormItem label="门户密码" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('userPassword',{
                            rules: [
                                {
                                  required: true,
                                  message: 'Please input user password!',
                                }]
                        })(
                            <Input  placeholder="请输入门户登录密码"/>
                    )}
                </FormItem>
                <FormItem label="API密钥" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('userKey',{
                            rules: [
                                {
                                  required: true,
                                  message: 'Please input api 密钥!',
                                }]
                        })(
                            <Input  placeholder="电信cmp9位;联通JS rest API;联通CMP openid"/>
                    )}
                </FormItem>
                <FormItem label="APP ID" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('appID',{
                            rules: [
                                {
                                  required: true,
                                  message: 'Please input APP ID!',
                                }]
                        })(
                            <Input  placeholder="移动appid，电信DCP-si账号，联通CMP-appid"/>
                    )}
                </FormItem>
                <FormItem label="APP 密钥" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('appSECRET',{
                            rules: [
                                {
                                  required: true,
                                  message: 'Please input APP appSecret!',
                                }]
                        })(
                            <Input  placeholder="移动app secret，电信DCP-si账号，联通CMP-appSC"/>
                    )}
                </FormItem>
                <FormItem label="备注" {...formItemLayout}>
                    {
                        getFieldDecorator('another',{
                            })(
                        <Input.TextArea rows={2} placeholder="备注"/>
                    )}
                </FormItem>
            </Form>
        );
    }
}
SourceForm = Form.create({})(SourceForm);


class FilterForm extends React.Component{

    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {span: 5},
            wrapperCol: {span: 16}
        };
        const userInfo = this.props.userInfo || {};
        const type = this.props.type;
        return (
            <Form layout="horizontal">
                <FormItem label="资源名称" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('sourceName',{
                            
                        })(
                            <Input  placeholder="请输入资源名称"/>
                    )}
                </FormItem>
                <FormItem label="运营商类型" {...formItemLayout}>
                    {
                        getFieldDecorator('operator',{
                            
                        })(
                        <Select>
                            <Option value={1}>移动</Option>
                            <Option value={2}>联通</Option>
                            <Option value={3}>电信</Option>
                        </Select>
                        )}
                </FormItem>
                <FormItem label="平台类型" {...formItemLayout}>
                    {
                        getFieldDecorator('operatorST',{
                            
                        })(
                        <Select>
                            <Option value={1}>移动CTBOSS</Option>
                            <Option value={2}>联通JASPER</Option>
                            <Option value={3}>联通CMP</Option>
                            <Option value={4}>电信DCP</Option>
                            <Option value={5}>电信CMP</Option>
                        </Select>
                    )}
                    
                </FormItem>
                <FormItem  label= "门户账号" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('userName',{
                            
                        })(
                            <Input  placeholder="请输入门户登录账号"/>
                    )}
                </FormItem> 
                <FormItem  label= "是否有效" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('isWork',{
                            
                        })(
                            <Select>
                             <Option value={1}>是</Option>
                             <Option value={2}>否</Option>
                            </Select>
                    )}
                </FormItem> 
            
            </Form>
        );
    }
}
FilterForm = Form.create({})(FilterForm);

// class ActionMenu extends React.Component{
//     handleMenuClick = e =>{
//         console.log('click', e);
//     }
//     render(){

//         return(
//             <Menu onClick={handleMenuClick}>
//               <Menu.Item key="1">导出</Menu.Item>

//             </Menu>
//         )
//     }
        
// }














