import React from 'react'
import { Layout, notification,Tabs, Icon} from 'antd';
// import Header from './components/AdminHeader'
// import Footer from './components/AdminFooter'
import SiderCustom from './components/SiderCustom';
import HeaderCustom from './components/HeaderCustom';
import Dashboard from './containers/Dashboard'
import axios from 'axios'
import {Redirect,withRouter} from 'react-router-dom'
import { loadData } from './redux/user.redux'
import { connect } from 'react-redux'
import request from './util/request'
import menuList from './config/menuConfig'
// import NavLeft from './components/NavLeft'
import './style/admin/index.less'
const { Content, Footer } = Layout;
const TabPane = Tabs.TabPane;
// import Home from './pages/home'
@withRouter
@connect(
	state=>state.user,
	{loadData}
)
class Admin extends React.Component{
	constructor(props){
		super(props)
		this.state = {
	        collapsed: false,
					currentTabKey: '',  // 当前激活的是哪个tab
					tabPanes: [],  // 当前总共有哪些tab
	    };
	}
	// async load(){
	// 	console.log("111");
	// 	await this.props.loadData()
	// 	console.log("222");
	//
	// }
    componentWillMount = () => {
        // const { receiveData } = this.props;
        // const user = JSON.parse(localStorage.getItem('user'));
        // user && this.props.user.username;
        // receiveData({a: 213}, 'auth');
        // fetchData({funcName: 'admin', stateName: 'auth'});
				this.tabTitleMap = this.parseTabTitle();
				this.updateTab(this.props);
        this.getClientWidth();
        window.onresize = () => {
            // console.log('屏幕变化了');
            this.getClientWidth();
            // console.log(document.body.clientWidth);
        }


    }

		/**
 * 每次在react-router中切换时也要判断是否更新tab
 */
componentWillReceiveProps(nextProps) {

	// FIXME: hack, 在react-router中切换时会触发这个方法两次, 据说是和hashHistory有关, 需要手动处理下
	const action = this.props.location.action;
	if (action === 'PUSH') {  // action有PUSH、POP、REPLACE等几种, 不太清楚分别是做什么用的
		return;
	}

	// FIXME: hack, 因为要区分react-router引起的re-render和redux引起的re-render
	if (this.props.collapse === nextProps.collapse) {
		this.updateTab(nextProps);
    // this.onTabChange(this.props.location)
    			// console.log("<<<<-----collapse.updateTab---->>>");

		// this.tabTitleMap = this.parseTabTitle();
	}
	// this.updateTab(nextProps);
}

    componentDidMount() {

    }
    getClientWidth = () => {    // 获取当前浏览器宽度并设置responsive管理响应式
        const { receiveData } = this.props;
        const clientWidth = document.body.clientWidth;
        // console.log(clientWidth);
        // receiveData({isMobile: clientWidth <= 992}, 'responsive');
    };
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

		// 下面开始是tab相关逻辑


  /**
   * 解析menu.js中的配置, 找到所有叶子节点对应的key和名称
   *
   * @returns {Map}
   */
  parseTabTitle() {
    const tabTitleMap = new Map();

    const addItem = item => {
      if (item.url) {  // 对于直接跳转的菜单项, 直接忽略, 只有headerMenu中可能有这种
        return;
      }
			// console.log("<<<<-----item.title---->>>"+item.title);
      if (item.icon) {
        tabTitleMap.set(item.key, <span className="ant-layout-tab-text"><Icon type={item.icon}/>{item.title}</span>);
      } else {
        tabTitleMap.set(item.key, <span className="ant-layout-tab-text">{item.title}</span>);
      }
    };
    const browseMenu = item => {
      if (item.sub) {
        item.sub.forEach(browseMenu);
      } else {
        addItem(item);
      }
    };

    // 又是dfs, 每次用js写这种就觉得很神奇...
    menuList.forEach(browseMenu);
    // headerMenu.forEach(browseMenu);

    // 最后要手动增加一个key, 对应于404页面
    tabTitleMap.set('*', <span className="ant-layout-tab-text"><Icon type="frown-o"/>Error</span>);
		// console.log("<<<<------tabTitleMap--->>>"+tabTitleMap);
    return tabTitleMap;
  }

  /**
   * 根据传入的props决定是否要新增一个tab
   *
   * @param props
   */
  updateTab(props) {
    const routes = props.location;
    let key = routes.pathname;  // react-router传入的key
		// console.log("<<<<-----path---->>>"+key);

    // 如果key有问题, 就直接隐藏所有tab, 这样简单点
    if (!key || !this.tabTitleMap.has(key)) {
      this.state.tabPanes.length = 0;
      return;
    }

    const tabTitle = this.tabTitleMap.get(key);

    // 如果允许同一个组件在tab中多次出现, 每次就必须生成唯一的key
    // if (globalConfig.tabMode.allowDuplicate === true) {
    //   if (!this.tabCounter) {
    //     this.tabCounter = 0;
    //   }
		//
    //   this.tabCounter++;
    //   key = key + this.tabCounter;
    // }

    // 更新当前选中的tab
    this.state.currentTabKey = key;

    // 当前key对应的tab是否已经在显示了?
    let exist = false;
    for (const pane of this.state.tabPanes) {
      if (pane.key == key) {
        exist = true;
        break;
      }
    }

    // 如果key不存在就要新增一个tabPane
    if (!exist) {
      // console.log("<<<<---props.children->>>>"+props.children)
      this.state.tabPanes.push({
        key,
        title: tabTitle,
        // content: React.cloneElement(props.children),  // 我本来是想clone一下children的, 这样比较保险, 不同tab不会互相干扰, 但发现似乎不clone也没啥bug
        content: props.children,
      })
      const { tabPanes } = this.state;
      this.setState({tabPanes,key})
      // this.props.history.push(key)

			return;
    }
  }

  /**
   * 改变tab时的回调
   */
  onTabChange = (activeKey) => {
		// console.log("<<<<-----activeKey---->>>"+activeKey);
    const { tabPanes } = this.state;
      // this.setState({tabPanes,key})
    
    this.setState({tabPanes,currentTabKey: activeKey});
    this.props.history.push(activeKey)

  };

  /**
   * 关闭tab时的回调
   */
  onTabRemove = (targetKey) => {
    // 如果关闭的是当前tab, 要激活哪个tab?
    // 首先尝试激活左边的, 再尝试激活右边的
    // console.log("<<<<-----targetKey---->>>"+targetKey);

    let nextTabKey = this.state.currentTabKey;
    if (this.state.currentTabKey === targetKey) {
      let currentTabIndex = -1;
      this.state.tabPanes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          currentTabIndex = i;
        }
      });

      // 如果当前tab左边还有tab, 就激活左边的
      if (currentTabIndex > 0) {
        nextTabKey = this.state.tabPanes[currentTabIndex - 1].key;
      }
      // 否则就激活右边的tab
      else if (currentTabIndex === 0 && this.state.tabPanes.length > 1) {
        nextTabKey = this.state.tabPanes[currentTabIndex + 1].key;
      }

      // 其实还有一种情况, 就是只剩最后一个tab, 但这里不用处理
    }

    // 过滤panes
    // console.log("<<<<-----this.state.tabPanes---->>>"+this.state.tabPanes);

    const newTabPanes = this.state.tabPanes.filter(pane => pane.key !== targetKey);
    // console.log("<<<<-----newTabPanes---->>>"+newTabPanes);
    this.setState({tabPanes: newTabPanes, currentTabKey: nextTabKey});
    this.props.history.push(nextTabKey)

  };

	  /**
	   * 渲染界面右侧主要的操作区
	   */
	  renderBody() {
	    // 我本来是在jsx表达式中判断globalConfig.tabMode.enable的, 比如{globalConfig.tabMode.enable && XXX}
	    // 后来想会不会拿到外面去判断好些, webpack会不会把这个语句优化掉? 好像有一些类似的机制
	    // 因为在编译的时候, globalConfig.tabMode.enable的值已经是确定的了, 下面的if-else其实是可以优化的
	    // 如果是jsx表达式那种写法, 感觉不太可能优化


	      // 如果没有tab可以显示, 就显示欢迎界面
				// console.log("<<<<-----this.state.tabPanes.length---->>>"+this.state.tabPanes.length);
	      // if (this.state.tabPanes.length === 0) {
	      //   return <Content className="ant-layout-container" style={{ margin: '0 16px', overflow: 'initial' }}>{<Dashboard />}</Content>;
	      // } else {
          // console.log("<<<<-----tWindows---->>>",window);
	        return <Tabs activeKey={this.state.currentTabKey} type="editable-card"
	                     onEdit={this.onTabRemove} onChange={this.onTabChange}
	                     hideAdd className="ant-layout-tab">
	          {this.state.tabPanes.map(pane => <TabPane tab={pane.title} key={pane.key}
	                                                    >	{pane.content}</TabPane>)}
	        </Tabs>;
	      // }

	  }

    render(){
			const _props = this.props
			// console.log("<<<<-----renders---->>>");
			// console.log("<<<<-----props---->>>"+_props.username);
        return (
          <Layout>
              {<SiderCustom collapsed={this.state.collapsed} />}
              <Layout style={{flexDirection: 'column'}}>
							<HeaderCustom toggle={this.toggle} collapsed={this.state.collapsed}  />
								{
                  // <Content style={{ margin: '0 16px', overflow: 'initial' }}>
										// {this.props.children}
                  // </Content>
								}
								{this.renderBody()}



                  <Footer style={{ textAlign: 'center' }}>
                  MNOIOT ©2018-2022 Created by b10050113@gmail.com
                  </Footer>
              </Layout>
            </Layout>
        )
    }
}
export default Admin
