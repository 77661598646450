
import React from 'react';
// import { resolve } from 'path'
// import img from './static/images/404.png';
// const r = path => resolve(__dirname, path)
import {Redirect} from 'react-router-dom'
import { connect } from 'react-redux'

@connect(
	state=>state.user
)

class Dashboard extends React.Component {
  constructor(props) {
		  super(props)
	  }
    render() {
      const _props = this.props
      // console.log("<<<<-----propsdashborad---->>>"+_props.username);
        return (
          _props.username?(
            <div>
              DashBoard
            </div>
          ):<Redirect to={this.props.redirectTo} />

        )
    }
}

export default Dashboard;
