import React from 'react'
import { Card, Form } from 'antd'
import axios from '../../axios'
import BaseForm from '../../components/BaseForm'
// import { BaiduMap,asyncWrapper ,NavigationControl,ScaleControl,MapTypeControl,OverviewMapControl} from 'react-baidu-maps';
// const AsyncMap = asyncWrapper(BaiduMap);
// var BMap = window.BMap//取出window中的BMap对象
// var map = new BMap.Map("allmap"); // 创建Map实例


export default class Order extends React.Component{

    state = {
        // mapState:false
    }
     BMap = window.BMap//取出window中的BMap对象
    // map = {}

    // 表单封装，通过构建表单对象，在BaseForm中进行统一渲染
    formList = [
        {
            type: '城市'
        }, {
            type: '时间查询'
        }
    ]

    params = {
        page:1
    }

    // 列表请求
    requestList = ()=>{
        // this.setState({mapState:true})
        // axios.ajax({
        //     url:'/map/bike_list',
        //     data:{
        //         params:this.params
        //     }
        // }).then((res)=>{
        //     if(res){
        //         this.setState({
        //             total_count:res.result.total_count
        //         },()=>{

        //         })
        //         this.renderMap(res.result);
        //     }
        // })
        this.renderMap();
    }

    // 查询表单
    handleFilterSubmit = (filterParams) => {
        this.params = filterParams;
        this.requestList();
    };

    componentDidMount(){
        
        // window.setTimeout(this.requestList(),100000)
        this.requestList()
        
    }
    componentWillUnmount(){
        // this.map = null
    }

    // 渲染地图
    renderMap = () => {
        // this.props.history.push('/admin/map')
        this.map = new BMap.Map("maper", {enableMapClick: false}); // 创建Map实例

        this.map.centerAndZoom(new BMap.Point(116.404, 39.915), 11); // 初始化地图,设置中心点坐标和地图级别
        this.map.addControl(new BMap.MapTypeControl()); //添加地图类型控件
        this.map.setCurrentCity("北京"); // 设置地图显示的城市 此项是必须设置的
        this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
        var top_right_control = new window.BMap.ScaleControl({anchor: window.BMAP_ANCHOR_TOP_LEFT});
        var top_right_navigation = new window.BMap.NavigationControl({anchor: window.BMAP_ANCHOR_TOP_LEFT});
        //添加控件和比例尺
        this.map.addControl(top_right_control);
        this.map.addControl(top_right_navigation);


        // console.log("<<<<-----renders---->>>");
        // let list = res.route_list;
        // this.map = new window.BMap.Map("container", {enableMapClick: false});
        // console.log("<<<<-----renders---->>>"+this.map);
        // let gps1 = list[0].split(',');
        // let startPoint = new window.BMap.Point(gps1[0], gps1[1]);
        // let gps2 = list[list.length - 1].split(',');
        // let endPoint = new window.BMap.Point(gps2[0], gps2[1]);
        // this.map.setCurrentCity("北京");
        // var point = new BMap.Point(116.404, 39.915);


        // this.map.centerAndZoom(point, 11);
        // this.map.enableScrollWheelZoom(true);
        // map.clearOverlays();

        //添加起始图标
        // let startPointIcon = new window.BMap.Icon("../../resource/assets/start_point.png", new window.BMap.Size(36, 42), {
        //     imageSize: new window.BMap.Size(36, 42),
        //     anchor: new window.BMap.Size(18, 42)
        // });

        // var bikeMarkerStart = new window.BMap.Marker(startPoint, { icon: startPointIcon });
        // this.map.addOverlay(bikeMarkerStart);

        // let endPointIcon = new window.BMap.Icon("../../resource/assets/end_point.png", new window.BMap.Size(36, 42), {
        //     imageSize: new window.BMap.Size(36, 42),
        //     anchor: new window.BMap.Size(18, 42)
        // });
        // var bikeMarkerEnd = new window.BMap.Marker(endPoint, { icon: endPointIcon });
        // this.map.addOverlay(bikeMarkerEnd);

        // let routeList = [];
        // list.forEach((item)=>{
        //     let p = item.split(",");
        //     let point = new window.BMap.Point(p[0], p[1]);
        //     routeList.push(point);
        // })
        // 行驶路线
        // var polyLine = new window.BMap.Polyline(routeList, {
        //     strokeColor: "#ef4136",
        //     strokeWeight: 3,
        //     strokeOpacity: 1
        // });
        // this.map.addOverlay(polyLine);

        // // 服务区路线
        // let serviceList = res.service_list;
        // let servicePointist = [];
        // serviceList.forEach((item) => {
        //     let point = new window.BMap.Point(item.lon, item.lat);
        //     servicePointist.push(point);
        // })
        // // 画线
        // var polyServiceLine = new window.BMap.Polyline(servicePointist, {
        //     strokeColor: "#ef4136",
        //     strokeWeight: 3,
        //     strokeOpacity: 1
        // });
        // this.map.addOverlay(polyServiceLine);

        // // 添加地图中的自行车
        // let bikeList = res.bike_list;
        // let bikeIcon = new window.BMap.Icon("../../resource/assets/bike.jpg", new window.BMap.Size(36, 42), {
        //     imageSize: new window.BMap.Size(36, 42),
        //     anchor: new window.BMap.Size(18, 42)
        // });
        // bikeList.forEach((item) => {
        //     let p = item.split(",");
        //     let point = new window.BMap.Point(p[0], p[1]);
        //     var bikeMarker = new window.BMap.Marker(point, { icon: bikeIcon });
        //     this.map.addOverlay(bikeMarker);
        // })

        // 添加地图控件
        // this.addMapControl();
    };

    // 添加地图控件
    addMapControl = () => {
        // let map = this.map;
        // // 左上角，添加比例尺
        // var top_right_control = new window.BMap.ScaleControl({anchor: window.BMAP_ANCHOR_TOP_RIGHT});
        // var top_right_navigation = new window.BMap.NavigationControl({anchor: window.BMAP_ANCHOR_TOP_RIGHT});
        // //添加控件和比例尺
        // map.addControl(top_right_control);
        // map.addControl(top_right_navigation);
        // map.enableScrollWheelZoom(true);
        // legend.addLegend(map);
    };

    render(){
        return (
            <div>
                <Card>
                    <BaseForm formList={this.formList} filterSubmit={this.handleFilterSubmit}/>
                </Card>
                <Card style={{marginTop:10}}>
                    <div>共{this.state.total_count}辆车</div>
                    <div id="maper" style={{ width: '100%', height: '500px' }}>
                        {/* <AsyncMap 
                        mapUrl="https://api.map.baidu.com/getscript?v=2.0&ak=1rW8OyVldBy2ex8d3O2GIXuY3erlhYbw"
                        loadingElement={<div>Loading.....</div>}
                        mapContainer={<div style={{ height: '100%' }} />} >
                        <NavigationControl
                        type="big"
                        anchor="top_right"
                        offset={{ width: 0, height: 30 }} />
                        <ScaleControl />
                        <MapTypeControl  />


                        </AsyncMap> */}
                    </div>
                </Card>
            </div>
        );
    }
}
